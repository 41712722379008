/* global I18n */

import Orejime from 'orejime/dist/orejime'
import WebsiteConfigs from 'website_configs/website_configs'

const lang = WebsiteConfigs.manualLanguages.includes(I18n.lang) ? I18n.lang : WebsiteConfigs.primaryLanguageCode
const langCode = lang.split('-')[0]

const orejimeConfigs = {
  elementID: 'cookies_consent',
  cookieName: 'cookies_consent',
  cookieExpiresAfterDays: 90,
  default: false,
  lang: langCode,
  privacyPolicy: (WebsiteConfigs.privacyPolicyPage || '/'),
  apps: [
    {
      name: 'strictly_necessary',
      title: I18n.t('strictly_necessary_cookies'),
      required: true
    },
    {
      name: 'functional',
      title: I18n.t('functional_cookies'),
      cookies: [
        'sort_by',
        'sort_by_direction',
        'per_page'
      ]
    },
    {
      name: 'analytics',
      title: I18n.t('analytics_cookies'),
      cookies: [
        'mhvid',
        '_ga',
        '_gat',
        '_gid'
      ]
    },
    {
      name: 'marketing',
      title: I18n.t('marketing_cookies'),
      cookies: [
        'VISITOR_INFO1_LIVE',
        'CONSENT',
        'YSC',
        'PREF',
        '__Secure-3PSIDCC',
        '__Secure-3PAPISID',
        '__Secure-3PSID',
        '__Secure-YEC',
        '__cf_bm',
        'player',
        'vuid'
      ]
    }
  ],
  translations: {
    [langCode]: {
      accept: I18n.t('accept'),
      decline: I18n.t('decline'),
      acceptAll: I18n.t('enable_all'),
      declineAll: I18n.t('decline_all'),
      save: I18n.t('save'),
      enabled: I18n.t('enabled'),
      disabled: I18n.t('disabled'),
      app: {
        required: { title: I18n.t('required'), description: I18n.t('this_application_is_always_required') }
      },
      consentNotice: {
        learnMore: I18n.t('learn_more'),
        description: I18n.t('we_use_cookies'),
        privacyPolicy: {
          name: I18n.t('privacy_policy'),
          text: WebsiteConfigs.privacyPolicyPage ? I18n.t('read_more_about_privacy_policy') : ''
        }
      },
      consentModal: {
        title: I18n.t('customize_consent_preferences'),
        description: I18n.t('we_use_cookies_modal'),
        privacyPolicy: {
          name: I18n.t('privacy_policy'),
          text: WebsiteConfigs.privacyPolicyPage ? I18n.t('read_more_about_privacy_policy') : ''
        }
      },
      strictly_necessary: {
        description: I18n.t('cookies_used_for_strictly_necessary')
      },
      functional: {
        description: I18n.t('cookies_used_for_functional')
      },
      analytics: {
        description: I18n.t('cookies_used_for_analytics')
      },
      marketing: {
        description: I18n.t('cookies_used_for_marketing')
      }
    }
  }
}

$(() => {
  const orejime = Orejime.init(orejimeConfigs)
  const consents = orejime.internals.manager.consents
  window.orejime = orejime

  if (window.gtag) {
    window.gtag('consent', 'update', {
      ad_storage: consents['marketing'] === true ? 'granted' : 'denied',
      ad_user_data: consents['marketing'] === true ? 'granted' : 'denied',
      ad_personalization: consents['marketing'] === true ? 'granted' : 'denied',
      analytics_storage: consents['analytics'] === true ? 'granted' : 'denied',
      personalization_storage: consents['functional'] === true ? 'granted' : 'denied',
      functionality_storage: consents['functional'] === true ? 'granted' : 'denied',
      security_storage: consents['functional'] === true ? 'granted' : 'denied'
    })
  }

  $('#cookies_consent').addClass('notranslate')

  $(document).on('click', '.consent-modal-button', (e) => {
    e.preventDefault()
    orejime.show()
  })
  $(document).on('click', '.orejime-Button--save, .orejime-Button--decline', (e) => window.location.reload())
})
